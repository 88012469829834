.popup-window {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #D2D2E0;
    width: 1000px;
    padding: 50px 50px;
    border: 1px solid #ccc;
    border-radius: 20px;
}

@media (max-width: 800px) {
    .popup-window {
        width: 260px;
            max-height: 550px;
            padding: 20px;
            left: 50%;
            overflow-y: auto;
            /* Enable vertical scrolling */
            border: 1px solid rgb(255, 255, 255);
            /* Optional: Add a border for better visualization */
            padding: 10px solid #696C99;
            ;
            /* Optional: Add padding for better visualization */
        /* Switch to column layout on smaller screens */
    }
}

.popup-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 800px) {
    .popup-content {
        flex-direction: column;
    }
}
.scrollable-content {
    padding: 10px;
}
.popup-art {
    max-width: 300px;
    margin-right: 100px;
    margin-bottom: 30px;
}

@media (max-width: 800px) {
    .popup-art {
        max-width: 150px;
            margin-right: 20px;
    }
}
.art-description {
    flex: 1;
    font-size: 18px;
    line-height: 1.8;
        padding-bottom: 50px;
    }
    
    p {
        font-family: "Alegreya", serif;
}
@media (max-width: 800px) {
    p {
        font-size: 16px;
    }
}

h2 {
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 35px;
    font-family: "Alegreya", serif;
}

h3 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Alegreya", serif;
}
.close-button {
    background-color: #696C99;
    /* purple background */
    border: none;
    /* Remove borders */
    color: white;
    /* White text */
    padding: 10px 20px;
    /* Some padding */
    text-align: center;
    /* Centered text */
    text-decoration: none;
    /* Remove underline */
    display: inline-block;
    /* Make the container inline-block */
    font-size: 16px;
    /* Increase font size */
    margin: 4px 2px;
    /* Some margin */
    cursor: pointer;
    /* Pointer/hand icon */
    border-radius: 4px;
    /* Rounded corners */
    transition: background-color 0.3s;
    /* Smooth transition for hover */
}

.close-button:hover {
    background-color: #3f4374;
    /* Darker purple on hover */
}