.title {
    font-family: 'Belleza', sans-serif;
    text-align: center;
    color: rgb(255, 255, 255);
    width: fit-content;
    font-size: 100px;
    margin-bottom: 10px;
    margin-top: 150px;
}

.subtitle {
    font-family: 'existencelight', sans-serif;
    font-size: 50px;
    color: white;
    width: fit-content;
    margin-top: 0%;
    margin-bottom: 20px;
}

@media (max-width: 500px) {
    .title {
        font-size: 60px;
        color: #121d32;
    }

    .subtitle {
        color: #121d32;
        font-size: 35px;
    }
}
.celeste-harte {
    display: flex;
    flex-direction: column;
    align-items: center;
}