/* styles.css */
.gallery-header {
    /* font */
    font-family: 'existencelight', sans-serif;
    text-align: left;
    color: rgb(189, 189, 189);
    font-size: 100px;

    /* padding */
    padding-top: 100px;

    /* margin */
    margin-bottom: 50px;
    margin-top: 50px;
    margin-left: 80px;
    width: 100vw;
}

.gallery-subheader {
    /* font */
    font-family: 'Belleza', sans-serif;
    text-align: left;
    color: rgb(189, 189, 189);
    font-size: 40px;
    /* margin */
        margin-top: 80px;
        margin-left: 150px;
    }
    
    .horizontal-line {
        width: 100vw;
        border: 0.5px solid rgb(189, 189, 189);
            margin: 0px 0;
        }
        
        .gallery-position {
            display: flex;
            flex-direction: row;
    /* Default to row layout */
    justify-content: center;
}
@media (max-width: 1000px) {
    .gallery-position {
        flex-direction: column;
        align-items: center;
        /* Switch to column layout on smaller screens */
    }

    .gallery-header {
        margin-left: 0px;
        /* Adjust margins for smaller screens */
        text-align: left;
        /* Center text for smaller screens */
        font-size: 70px;
        /* Adjust font size for smaller screens */
    }

    .gallery-subheader {
        margin-left: 20px;
        /* Adjust margins for smaller screens */
        text-align: center;
        /* Center text for smaller screens */
    }

    .horizontal-line {
        margin: 20px 0;
        /* Adjust margins for the horizontal line */
    }
}

@media (max-width: 600px) {
    .gallery-header {
        font-size: 50px;
        margin-left: 20px;
        /* Further adjust font size for even smaller screens */
    }

    .gallery-subheader {
        font-size: 30px;
        /* Adjust font size for smaller screens */
    }
}