.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    height: 100vh;
    text-align: left;
    background-attachment: fixed;
    /* Choose your background color */
}

.text-container {
    text-align: center;
    padding-bottom: 300px;
    color: #333;
    /* Choose your text color */
}

.text-container h1 {
    font-family: 'existencelight', sans-serif;
    font-size: 5rem;
    /* Adjust the font size as needed */
    margin-bottom: 0;
}

.text-container p {
    font-family: 'commelight', sans-serif;
    font-size: 30px;
    /* Adjust the font size as needed */
}