/* GalleryItems.css */

.gallery-item {
    position: relative;
    margin-right: 90px;
    margin-left: 100px;
    margin-bottom: 50px;
    margin-top: 50px;
    }
    
        .gallery-item-img {
            width: 200px;
        height: 300px;
        object-fit: cover;
        }

  .gallery-item-text {
    margin-right: 0px;
        margin-bottom: 50px;
        margin-left: 0px;
      font-family: 'commeregular', Arial, Helvetica, sans-serif;
      font-size: 25px;
      color: rgb(189, 189, 189);
    text-align: center;
    }
  .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 200px;
        height: 300px;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
    transition: opacity 0.3s ease;
    }
  .gallery-item:hover .overlay {
      opacity: 1;
  }
  .overlay-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 18px;
      text-align: center;
  }
.gallery-item-text {
  margin-top: 10px;
  font-weight: bold;
}

.gallery-item-info {
  margin-top: 10px;
}

.gallery-item-title {
  font-size: 18px;
  color: #ff0000;
}

.gallery-item-subtitle {
  font-size: 14px;
  color: #00e1ff;
}