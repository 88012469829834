.menu {
    display: flex;
    background: rgba(0, 0, 0, 0.7);
    margin: 0 auto;
    width: 100%;
    padding-top: 20px;
        padding-bottom: 30px;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    order: 1;
    z-index: 1000;
    /* Add more styles as needed */
}

.menu_item {
    text-align: center;
    padding: 0 3%;
}

.menu_text {
    color: white;
    font-family: 'Montserrat Alternates', sans-serif;
        font-size: 18pt;
}

@media (max-width: 500px) {
    .menu {
        justify-content: left;
    }

    .menu_text {
        font-size: 10pt;
    }

    .menu_item {
        text-align: center;
        padding: 0 3.5%;
    }
}
.menu a {
    text-decoration: none;
}

.menu a:hover {
    color: rgb(130, 132, 215);
}