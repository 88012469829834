@import url(https://fonts.googleapis.com/css2?family=Belleza&family=Montserrat+Alternates:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Belleza&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400..900;1,400..900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'commelight';
  src: url(/static/media/Comme-Light-webfont.90428063.woff) format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'commesemibold';
  src: url(/static/media/Comme-SemiBold-webfont.2f944298.woff) format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'commeregular';
  src: url("/static/media/Comme-Regular (1)-webfont.717b84a2.woff") format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'commeextralight';
  src: url(/static/media/Comme-ExtraLight-webfont.50a8aaae.woff) format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'existencelight';
  src: url(/static/media/Existence-Light-webfont.528f0ec3.woff) format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'existencestencillight';
  src: url(/static/media/Existence-StencilLight-webfont.4f76dd8e.woff) format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'existenceunicaselight';
  src: url(/static/media/Existence-UnicaseLight-webfont.38b1cf74.woff) format('woff');
  font-weight: normal;
  font-style: normal;

}
p {
  font-family: 'commelight', Arial, Helvetica, sans-serif;
}
html {
  scroll-behavior: smooth;
}
.App {
    background-position: center;
      background-size: cover;
      height: 133vh;
      text-align: left;
      background-attachment: fixed;
    }


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .App {
    background-size: 1700px, 500px;
    /* Adjust the scaling method */
    background-position: top;
    /* Adjust the position */
    background-attachment: scroll;
    /* Allow the background to scroll with the content */
    height: auto;
    /* Allow the height to adjust based on content */
  }
}
.title {
    font-family: 'Belleza', sans-serif;
    text-align: center;
    color: rgb(255, 255, 255);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 100px;
    margin-bottom: 10px;
    margin-top: 150px;
}

.subtitle {
    font-family: 'existencelight', sans-serif;
    font-size: 50px;
    color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 0%;
    margin-bottom: 20px;
}

@media (max-width: 500px) {
    .title {
        font-size: 60px;
        color: #121d32;
    }

    .subtitle {
        color: #121d32;
        font-size: 35px;
    }
}
.celeste-harte {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.featured-book img {
    width: 750px;
    object-fit: cover;
}

@media (max-width: 1000px) {
    .featured-book img {
        width: 500px;
        /* Switch to column layout on smaller screens */
    }
}
.featured-book-text {
    color: white;
    font-size: 24px;
    /* Add other styles for the text as needed */
}

.featured-book {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
        padding-right: 70px;
        }
        
@media (max-width: 1000px) {
    .featured-book {
        padding-left: 100px;
        /* Switch to column layout on smaller screens */
    }
@media (max-width: 500px) {
    .featured-book img {
        width: 450px;
    }
}

@media (max-width: 500px) {
    .featured-book {
        padding-left: 70px;
    }
}
}
.button {
    display: inline-block;
    padding: 20px 30px;
        margin-top: 50px;
        border-radius: 50px;
    text-decoration: none;
    color: #ffffff;
    border: 4px solid #17171A;
    background-color: #17171A;
    transition: background-color 0.2s ease;
    font-size: 25px;
}

.button:hover {
    border: 4px solid #17171A;
    background-color: #2B3145;
}
.home-section {
    display: flex;
    margin-bottom: 90px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    /* Additional styling for the home section */
}

.home-section__content {
    margin-top: 168px;
    display: flex;
    /* Additional styling for the content area */
}
@media (max-width: 1000px) {
    .home-section__content {
        margin-top: 0px;
        flex-direction: column-reverse;
        align-items: center;
        /* Switch to column layout on smaller screens */
    }
}
/* GalleryItems.css */

.gallery-item {
    position: relative;
    margin-right: 90px;
    margin-left: 100px;
    margin-bottom: 50px;
    margin-top: 50px;
    }
    
        .gallery-item-img {
            width: 200px;
        height: 300px;
        object-fit: cover;
        }

  .gallery-item-text {
    margin-right: 0px;
        margin-bottom: 50px;
        margin-left: 0px;
      font-family: 'commeregular', Arial, Helvetica, sans-serif;
      font-size: 25px;
      color: rgb(189, 189, 189);
    text-align: center;
    }
  .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 200px;
        height: 300px;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
    transition: opacity 0.3s ease;
    }
  .gallery-item:hover .overlay {
      opacity: 1;
  }
  .overlay-text {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      color: white;
      font-size: 18px;
      text-align: center;
  }
.gallery-item-text {
  margin-top: 10px;
  font-weight: bold;
}

.gallery-item-info {
  margin-top: 10px;
}

.gallery-item-title {
  font-size: 18px;
  color: #ff0000;
}

.gallery-item-subtitle {
  font-size: 14px;
  color: #00e1ff;
}
.popup-window {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: #D2D2E0;
    width: 1000px;
    padding: 50px 50px;
    border: 1px solid #ccc;
    border-radius: 20px;
}

@media (max-width: 800px) {
    .popup-window {
        width: 260px;
            max-height: 550px;
            padding: 20px;
            left: 50%;
            overflow-y: auto;
            /* Enable vertical scrolling */
            border: 1px solid rgb(255, 255, 255);
            /* Optional: Add a border for better visualization */
            padding: 10px solid #696C99;
            ;
            /* Optional: Add padding for better visualization */
        /* Switch to column layout on smaller screens */
    }
}

.popup-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 800px) {
    .popup-content {
        flex-direction: column;
    }
}
.scrollable-content {
    padding: 10px;
}
.popup-art {
    max-width: 300px;
    margin-right: 100px;
    margin-bottom: 30px;
}

@media (max-width: 800px) {
    .popup-art {
        max-width: 150px;
            margin-right: 20px;
    }
}
.art-description {
    flex: 1 1;
    font-size: 18px;
    line-height: 1.8;
        padding-bottom: 50px;
    }
    
    p {
        font-family: "Alegreya", serif;
}
@media (max-width: 800px) {
    p {
        font-size: 16px;
    }
}

h2 {
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 35px;
    font-family: "Alegreya", serif;
}

h3 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 25px;
    font-family: "Alegreya", serif;
}
.close-button {
    background-color: #696C99;
    /* purple background */
    border: none;
    /* Remove borders */
    color: white;
    /* White text */
    padding: 10px 20px;
    /* Some padding */
    text-align: center;
    /* Centered text */
    text-decoration: none;
    /* Remove underline */
    display: inline-block;
    /* Make the container inline-block */
    font-size: 16px;
    /* Increase font size */
    margin: 4px 2px;
    /* Some margin */
    cursor: pointer;
    /* Pointer/hand icon */
    border-radius: 4px;
    /* Rounded corners */
    transition: background-color 0.3s;
    /* Smooth transition for hover */
}

.close-button:hover {
    background-color: #3f4374;
    /* Darker purple on hover */
}
/* styles.css */
.gallery-header {
    /* font */
    font-family: 'existencelight', sans-serif;
    text-align: left;
    color: rgb(189, 189, 189);
    font-size: 100px;

    /* padding */
    padding-top: 100px;

    /* margin */
    margin-bottom: 50px;
    margin-top: 50px;
    margin-left: 80px;
    width: 100vw;
}

.gallery-subheader {
    /* font */
    font-family: 'Belleza', sans-serif;
    text-align: left;
    color: rgb(189, 189, 189);
    font-size: 40px;
    /* margin */
        margin-top: 80px;
        margin-left: 150px;
    }
    
    .horizontal-line {
        width: 100vw;
        border: 0.5px solid rgb(189, 189, 189);
            margin: 0px 0;
        }
        
        .gallery-position {
            display: flex;
            flex-direction: row;
    /* Default to row layout */
    justify-content: center;
}
@media (max-width: 1000px) {
    .gallery-position {
        flex-direction: column;
        align-items: center;
        /* Switch to column layout on smaller screens */
    }

    .gallery-header {
        margin-left: 0px;
        /* Adjust margins for smaller screens */
        text-align: left;
        /* Center text for smaller screens */
        font-size: 70px;
        /* Adjust font size for smaller screens */
    }

    .gallery-subheader {
        margin-left: 20px;
        /* Adjust margins for smaller screens */
        text-align: center;
        /* Center text for smaller screens */
    }

    .horizontal-line {
        margin: 20px 0;
        /* Adjust margins for the horizontal line */
    }
}

@media (max-width: 600px) {
    .gallery-header {
        font-size: 50px;
        margin-left: 20px;
        /* Further adjust font size for even smaller screens */
    }

    .gallery-subheader {
        font-size: 30px;
        /* Adjust font size for smaller screens */
    }
}
.books-section {
    background-color: #1E1E1E;
}
.meet-the-characters-section {
    background-color: #1E1E1E;
}
/* styles.css */
.about-me-section {
    background-color: #1E1E1E;
    display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 100vh;
}

.about-me-positioning {
    display: flex;
}

.about-me-description {
    font-family: 'commeregular', Arial, Helvetica, sans-serif;
    text-align: center;
    color: #dbdbdb;
    font-size: 20px;
    margin-bottom: 0px;
    line-height: 1.7;
    width: 700px;
        /* Default fixed width */
        transition: width 0.3s;
    }
    
    @media (max-width: 768px) {
        .about-me-description {
            width: 500px;
            /* Fixed width for smaller screens */
        }
    }
    
    @media (max-width: 480px) {
        .about-me-description {
            width: 300px;
            /* Fixed width for even smaller screens */
        }
}
.profile-pic img {
    width: 350px;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
}

.profile-pic {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    padding-bottom: 50px;
}
.menu {
    display: flex;
    background: rgba(0, 0, 0, 0.7);
    margin: 0 auto;
    width: 100%;
    padding-top: 20px;
        padding-bottom: 30px;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    order: 1;
    z-index: 1000;
    /* Add more styles as needed */
}

.menu_item {
    text-align: center;
    padding: 0 3%;
}

.menu_text {
    color: white;
    font-family: 'Montserrat Alternates', sans-serif;
        font-size: 18pt;
}

@media (max-width: 500px) {
    .menu {
        justify-content: left;
    }

    .menu_text {
        font-size: 10pt;
    }

    .menu_item {
        text-align: center;
        padding: 0 3.5%;
    }
}
.menu a {
    text-decoration: none;
}

.menu a:hover {
    color: rgb(130, 132, 215);
}
.footer {
    background-color: #1E1E1E;
    display: flex;
    grid-gap: 50px;
    gap: 50px;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
}
.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    height: 100vh;
    text-align: left;
    background-attachment: fixed;
    /* Choose your background color */
}

.text-container {
    text-align: center;
    padding-bottom: 300px;
    color: #333;
    /* Choose your text color */
}

.text-container h1 {
    font-family: 'existencelight', sans-serif;
    font-size: 5rem;
    /* Adjust the font size as needed */
    margin-bottom: 0;
}

.text-container p {
    font-family: 'commelight', sans-serif;
    font-size: 30px;
    /* Adjust the font size as needed */
}
