.featured-book img {
    width: 750px;
    object-fit: cover;
}

@media (max-width: 1000px) {
    .featured-book img {
        width: 500px;
        /* Switch to column layout on smaller screens */
    }
}
.featured-book-text {
    color: white;
    font-size: 24px;
    /* Add other styles for the text as needed */
}

.featured-book {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
        padding-right: 70px;
        }
        
@media (max-width: 1000px) {
    .featured-book {
        padding-left: 100px;
        /* Switch to column layout on smaller screens */
    }
@media (max-width: 500px) {
    .featured-book img {
        width: 450px;
    }
}

@media (max-width: 500px) {
    .featured-book {
        padding-left: 70px;
    }
}
}