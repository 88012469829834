.home-section {
    display: flex;
    margin-bottom: 90px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    /* Additional styling for the home section */
}

.home-section__content {
    margin-top: 168px;
    display: flex;
    /* Additional styling for the content area */
}
@media (max-width: 1000px) {
    .home-section__content {
        margin-top: 0px;
        flex-direction: column-reverse;
        align-items: center;
        /* Switch to column layout on smaller screens */
    }
}