.button {
    display: inline-block;
    padding: 20px 30px;
        margin-top: 50px;
        border-radius: 50px;
    text-decoration: none;
    color: #ffffff;
    border: 4px solid #17171A;
    background-color: #17171A;
    transition: background-color 0.2s ease;
    font-size: 25px;
}

.button:hover {
    border: 4px solid #17171A;
    background-color: #2B3145;
}